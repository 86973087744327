import { useState, useEffect, } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import { idCodes } from '../../../../assets/helpers/options';
import { statesService } from '../../../../services/statesService';
import { vehicleService } from '../../../../services/vehicleService';
import { membershipService } from '../../../../services/membershipService';
import { clearObject, capitalize, removeBlankSpacesAndUppercase } from '../../../../assets/helpers/funciones';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { vehiclesEnum } from "../../../../assets/helpers/options"
import './LandingForms.scss';

export const FreeCreateVehicleForm = (props) => {
  const { setcurentStep, curentStep, membership, customer } = props;

  const t = useTranslation();

  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [ownerCitiesOptions, setOwnerCitiesOptions] = useState([]);
  const [vehicleBrandOptions, setVehicleBrandOptions] = useState([]);
  const [vehicleModelOptions, setVehicleModelOptions] = useState([]);
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [municipalitiesOptions, setMunicipalitiesOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [customerIsOwner, setcustomerIsOwner] = useState('SI');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schemas.RegisterFreeVehicleSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    data.membership = membership?._id;
    data.plate = data?.plate?.toUpperCase();
    data.color = capitalize(data?.color);
    data.customer = customer
    data = clearObject(data);
    data.armored = false;


    let vehicle = { ...data };

    if (vehicle?.armorType === 'ninguno') {
      delete vehicle.armorType;
    }

    vehicle.brandName = capitalize(
      vehicleBrandOptions?.filter((c) => c?._id === vehicle?.brand)[0]?.name
    );
    vehicle.modelName = vehicleModelOptions?.filter(
      (c) => c?._id === vehicle?.model
    )[0]?.name;

    vehicle.stateName = capitalize(
      statesOptions?.filter((c) => c?._id === vehicle?.state)[0]?.name
    );
    vehicle.cityName = capitalize(
      citiesOptions?.filter((c) => c?._id === vehicle?.city)[0]?.name
    );
    vehicle.municipalityName = capitalize(
      municipalitiesOptions?.filter((c) => c?._id === vehicle?.municipality)[0]
        ?.name
    );

    if (!isLoading) {
      setIsloading(true);
      vehicleService
        .createVehicleF4(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            setIsloading(false);
            sessionStorage.setItem('vehicle', JSON.stringify(vehicle));
            updateMembershipStatus(membership?._id)
            setcurentStep(curentStep + 1);
          } else {
            setIsloading(false);
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getCities = (state) => {
    statesService
      .getCities(state ? state : watch('state'))
      .then((response) => {
        if (response.status === 200) {
          setCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const updateMembershipStatus = (membershipId) => {
    setIsloading(true);
    membershipService
      .updateMembershipStatus(membershipId)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          setcurentStep(curentStep + 1);
          setIsloading(false);
        } else {
          setIsloading(false);
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };


  const getOwnerCities = (ownerState) => {
    statesService
      .getCities(ownerState ? ownerState : watch('ownerState'))
      .then((response) => {
        if (response.status === 200) {
          setOwnerCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };

  const getMunicipalities = (city) => {
    statesService
      .getMunicipalities(city ? city : watch('city'))
      .then((response) => {
        if (response.status === 200) {
          setMunicipalitiesOptions(response?.data?.municipalities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };



  const getSalesVehicleClasses = async () => {
    vehicleService
      .getSalesVehicleClasses()
      .then((response) => {
        if (response.status === 200) {
          setVehicleClassesOptions(response?.data?.classes);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };




  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then((response) => {
        if (response.status === 200) {
          setVehicleBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };



  const getVehicleModels = (vehicleBrand) => {
    if (vehicleBrand) {
      vehicleService.getVehicleModels(vehicleBrand).then((response) => {
        if (response.status === 200) {
          setVehicleModelOptions(response?.data?.models);
        } else {
          notify(`${response.message}`, 'error');
        }
      });
    }
  };


  const preloadValues = () => {
    setValue('customerIsOwner', true);
  };


  const getIdentification = () => {
    let identification = sessionStorage?.getItem('identification');
    if (getIdentification) {
      setValue('plate', identification)
      return true
    }
  }

  useEffect(() => {
    getStates();
    getVehicleBrands();
    getSalesVehicleClasses()
    preloadValues()
  }, []);

  let validateDisabled = () => {
    return false;
  };

  let filteredClasses = vehicleClassesOptions?.filter(v => [vehiclesEnum?.AUTOMOVIL, vehiclesEnum?.CAMIONETA, vehiclesEnum?.MOTO, vehiclesEnum?.RUSTICO]?.includes(v?.name))


  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="landing-form-container">
        <h5>Información del Vehículo</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <form>
          <div className="row">
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Clase *'}
              type={'select'}
              name={'class'}
              register={register}
              placeholder={''}
              options={filteredClasses}
              errors={errors?.class?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={sessionStorage?.getItem('identification') ? 'C.I / RIF*' : 'Placa *'}
              type={'text'}
              name={'plate'}
              register={register}
              placeholder={''}
              errors={errors?.plate?.message}
              onChange={(e) => removeBlankSpacesAndUppercase(watch('plate'), 'plate', setValue)}
            // disabled={getIdentification()}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Marca *'}
              type={'select'}
              options={vehicleBrandOptions}
              name={'brand'}
              register={register}
              placeholder={''}
              errors={errors?.brand?.message}
              disabled={validateDisabled()}
              onChange={() => getVehicleModels(watch('brand'))}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Modelo *'}
              type={'select'}
              name={'model'}
              register={register}
              placeholder={''}
              options={vehicleModelOptions}
              errors={errors?.model?.message}
              disabled={!watch("brand")}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Color *'}
              type={'text'}
              name={'color'}
              register={register}
              placeholder={''}
              errors={errors?.color?.message}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Año *'}
              type={'number'}
              name={'year'}
              register={register}
              placeholder={''}
              control={control}
              errors={errors?.year?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Estado *'}
              type={'select'}
              options={statesOptions}
              name={'state'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.state?.message}
              onChange={(e) => getCities(watch('state'))}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Ciudad *'}
              type={'select'}
              options={citiesOptions}
              name={'city'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.city?.message}
              onChange={(e) => getMunicipalities()}
              disabled={!watch("state")}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Municipio *'}
              type={'select'}
              options={municipalitiesOptions}
              name={'municipality'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.municipality?.message}
              disabled={!watch("city")}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Dirección*'}
              type={'textarea'}
              name={'address'}
              placeholder={
                'Ejemplo: Chacao Av. Blandín, con Calle Santa Teresa de Jesús, C.C. San Ignacio, Sotano 1'
              }
              register={register}
              errors={errors?.address?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Punto de referencia *'}
              type={'textarea'}
              name={'addressReference'}
              placeholder={
                'Ejemplo: Edificio blanco frente a la bomba de gasolina'
              }
              register={register}
              errors={errors?.addressReference?.message}
              disabled={validateDisabled()}
            />{' '}
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6 checbox-input-container">
              {/* <label htmlFor="">¿Eres el titular del vehículo?</label>
              <div className="checbox-input-container-midle">
                <div className="topping">
                  <input
                    type="checkbox"
                    id="topping"
                    name="customerIsOwner"
                    value={'SI'}
                    checked={customerIsOwner === 'SI'}
                    onChange={handlecustomerIsOwner}
                  />
                  Si
                </div>{' '}
                <div className="topping">
                  <input
                    type="checkbox"
                    id="topping"
                    name="customerIsOwner"
                    value={'NO'}
                    checked={customerIsOwner === 'NO'}
                    onChange={handlecustomerIsOwner}
                  />
                  No
                </div>
                <div className="result">
                </div>
              </div> */}
            </div>
            {customerIsOwner === 'NO' ? (
              <>
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Nombre del titular *'}
                  type={'text'}
                  name={'ownerFirstName'}
                  register={register}
                  placeholder={''}
                  errors={errors?.ownerFirstName?.message}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Apellido del titular *'}
                  type={'text'}
                  name={'ownerLastName'}
                  register={register}
                  placeholder={''}
                  errors={errors?.ownerLastName?.message}
                />{' '}
                <Input
                  bootstrap={'col-4 col-sm-2 col-md-2'}
                  label={'C.I / RIF*'}
                  type={'select'}
                  name={'ownerIdentificationType'}
                  register={register}
                  placeholder={''}
                  options={idCodes}
                  errors={errors?.ownerIdentificationType?.message}
                />{' '}
                <Input
                  bootstrap={'col-8 col-sm-4 col-md-4'}
                  label={'del titular*'}
                  type={'number'}
                  name={'ownerIdentification'}
                  register={register}
                  placeholder={''}
                  errors={errors?.ownerIdentification?.message}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Estado del titular *'}
                  type={'select'}
                  options={statesOptions}
                  name={'ownerState'}
                  placeholder={'Seleccione'}
                  register={register}
                  errors={errors?.ownerState?.message}
                  onChange={(e) => getOwnerCities()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Ciudad del titular *'}
                  type={'select'}
                  options={ownerCitiesOptions}
                  name={'ownerCity'}
                  placeholder={'Seleccione'}
                  register={register}
                  errors={errors?.ownerCity?.message}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Dirección del titular *'}
                  type={'textarea'}
                  name={'ownerAddress'}
                  placeholder={''}
                  register={register}
                  errors={errors?.ownerAddress?.message}
                />
              </>
            ) : null}
          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <AltButton
              label={'Regresar'}
              action={() => setcurentStep(curentStep - 1)}
            />
            <ActionButton label={'Siguiente'} action={onSubmit} />
          </div>
        </form>
      </div>
    </>
  );
};
