import React, { useCallback } from 'react';
import uploadImage from '../../../images/uploadImage.svg';
import fileImage from '../../../images/file.png';
import closeOutline from '../../../images/close-outline.svg';
import { useDropzone } from 'react-dropzone';
import { notify } from '../../../assets/helpers/toast';
import './Dropzone.scss';

export const FileComponent = (props) => {
  const { file, remove } = props;

  const truncateString = (str) => {

    const stringLength = 20
    if (str.length > stringLength) {
      return str.substring(0, stringLength) + '...';
    }
    return str;
  };


  return (
    <div className="file-component">
      <div>
        <img src={fileImage} alt="file" className="file" />
        <h6>{truncateString(file.name)}</h6>
      </div>
      {remove && (
        <img
          onClick={() => remove()}
          src={closeOutline}
          alt="closeOutline"
          className="file-close"
        />
      )}
    </div>
  );
};

export const Dropzone = (props) => {
  const { getRootProps, getInputProps, message } = props;

  return (
    <div className="file-dropzone-component">
      <div {...getRootProps({ name: 'testtesttest' })}>
        <img src={uploadImage} alt="upload" />
        <input
          name="testtesttest"
          {...getInputProps({ name: 'testtesttest' })}
        />
        <p>{message}</p>
      </div>
    </div>
  );
};

export const NewDropzone = (props) => {
  const {
    index,
    fileUploaded,
    img,
    setFileUploaded,
    setShowImages,
    showImages,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        let files = fileUploaded;

        files[index] = acceptedFiles[0];
        setFileUploaded([...files]);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeFile = (index) => {
    let files = fileUploaded;
    files[index] = {};
    setFileUploaded([...files]);
  };

  const changeUploadedImage = (index) => {
    let show = showImages;
    show[index] = !show[index];
    setShowImages([...show]);
  };

  return (
    <div className="dropzone-container">
      {img?.label && <h6>{img?.label}</h6>}

      {img?.uploaded && showImages[index] ? (
        <FileComponent
          file={{ name: img?.label + 'A' }}
          remove={() => changeUploadedImage(index)}
        />
      ) : fileUploaded[index]?.name ? (
        <FileComponent
          file={fileUploaded[index]}
          remove={() => removeFile(index)}
        />
      ) : (
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          message={img?.message}
        />
      )}

      {/* {img?.label && showImages[index] ? (
        <FileComponent
          file={{ name: img?.label }}
          remove={() => changeUploadedImage(index)}
        />
      ) : fileUploaded[index]?.name ? (
        <FileComponent
          file={fileUploaded[index]}
          remove={() => removeFile(index)}
        />
      ) : (
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          message={img?.message}
        />
      )} */}
    </div>
  );
};

export const SalesDropzone = (props) => {
  const { getRootProps, getInputProps, message, instructions, maxWeight, error } =
    props;

  return (
    <div className="file-dropzone-component">
      <h6>{instructions}</h6>
      <p>{maxWeight}</p>
      <div className={`sales-dropzone-${error}`} {...getRootProps({ name: 'testtesttest' })}>
        <img src={uploadImage} alt="upload" />
        <input
          name="testtesttest"
          {...getInputProps({ name: 'testtesttest' })}
        />
        <p>{message}</p>
      </div>
    </div>
  );
};
