import Pagination from '@material-ui/lab/Pagination';
import navOptionArrow from '../../../images/navOptionArrow.svg';
import editLogo from '../../../images/edit.svg';
import searchLogo from '../../../images/search.svg';
import trash from '../../../images/trash.svg';
import view from '../../../images/view.svg';
import add from '../../../images/add.svg';
import eye from '../../../images/eye.svg';
import offLogo from '../../../images/off.svg';
import onLogo from '../../../images/on.svg';
import renewLogo from '../../../images/renewLogo.svg';
import checkStatus from '../../../images/checkStatus.svg';
import close from '../../../images/close.svg';
import calendarGray from '../../../images/calendarGray.svg';
import authService from '../../../services/authService';
import grayCheckboxSelected from '../../../images/grayCheckboxSelected.svg';
import grayCheckboxUnselected from '../../../images/grayCheckboxUnselected.svg';
import { ActionButton, TableActionButton, AltButton } from '../Buttons/Buttons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  capitalize,
  exists,
  checkMissingVehicleFields,
  getMonthInSpanish,
} from '../../../assets/helpers/funciones';
import {
  exportablesTypes,
  planStatus,
  fleetLoadPaymentStatus,
  paymentType,
  userType,
} from '../../../assets/helpers/options';
import { salesRoles, transcriptRoles } from '../../../assets/helpers/roles';
import moment from 'moment';
import { TableLoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { serviceName, reasignTime } from '../../../assets/helpers/funciones';
import './Tables.scss';

const editURL = `/afiliations/edit-order`;

const DuepaymentsColumn = props => {
  const { payment } = props;

  return (
    <>
      <td>{payment?.totalAmount}</td>
      <td>
        {payment?.totalAmountInBsS ? payment?.totalAmountInBsS?.toFixed(2) : ''}
      </td>
      <td>{capitalize(payment?.method)}</td>
      <td>{payment?.reference}</td>
    </>
  );
};

let cuentasPorCobrar = d => {
  if (d?.paymentType === paymentType?.PENDING) {
    return `${d?.priceInfo?.finalPrice?.toFixed(2)} `;
  }
  if (d?.paymentType === paymentType?.FINANCIADO) {
    return `${d?.paymentDues[1]?.amount?.toFixed(2)} `;
  }

  if (d?.paymentType === paymentType?.CASHEA) {
    let amount = 0;

    for (let i = 0; i < d?.paymentDues?.length; i++) {
      if (!d?.paymentDues[i]?.paymentDate) {
        amount = amount + d?.paymentDues[i]?.amount;
      }
    }
    return `${amount?.toFixed(2)} `;
  } else return null;
};

let statusCuentasPorCobrar = d => {
  if (
    [
      paymentType?.PENDING,
      paymentType?.FINANCIADO,
      paymentType?.CASHEA,
    ].includes(d?.paymentType)
  ) {
    return `${d?.paymentStatus} `;
  } else return null;
};

export const Tables = props => {
  const { header, dataTable, handlePageChange, edit } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>

          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.invoiceName || d?.companyName)}</td>
                <td>{capitalize(d?.contactName)}</td>
                <td>{exists(d?.phonePrefix) + '-' + exists(d?.phoneNumber)}</td>
                <td>
                  {exists(d?.identificationType) +
                    ': ' +
                    exists(d?.identification)}
                </td>
                <td>{capitalize(d?.state)}</td>
                <td>{exists(d?.isActive)}</td>
                <td>
                  <div className="table-buttons-conatiner">
                    {/* <TableActionButton src={view} /> */}
                    <TableActionButton src={editLogo} action={() => edit(d)} />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetsLoadTable = props => {
  const { header, data, showDetail } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.id}</td>
              <td>{d?.name}</td>
              <td>{d?.code}</td>
              <td>
                <img src={checkStatus} alt="checkStatus" />
              </td>
              <td>{d?.load}</td>
              <td>{d?.modified}</td>
              <td>{d?.actions}</td>
              <td>
                <div className="table-buttons-conatiner">
                  <TableActionButton src={view} action={() => showDetail(d)} />
                  <TableActionButton src={editLogo} />
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const LoadTable = props => {
  const { header, data, openModal, showDetail } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.name1}</td>
              <td>{d?.name2}</td>
              <td>{d?.name3}</td>
              <td>{d?.name4}</td>
              <td>
                <span
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => openModal()}
                >
                  {d?.name5}
                </span>
              </td>
              <td>{d?.name6}</td>
              <td>{d?.name7}</td>
              <td>{d?.name8}</td>
              <td>{d?.name9}</td>
              <td>{d?.name10}</td>
              <td>
                {' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => showDetail()}
                >
                  {' '}
                  Ver detalle
                </span>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const LoadDetailTable = props => {
  const { header, data, openModal, showDetail } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.name1}</td>
              <td>{d?.name2}</td>
              <td>{d?.name3}</td>
              <td>{d?.name4}</td>
              <td>{d?.name5}</td>
              <td>{d?.name6}</td>
              <td>{d?.name7}</td>
              <td>{d?.name8}</td>
              <td>{d?.name9}</td>
              <td>{d?.name10}</td>
              <td>{d?.name11}</td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const HistoryPaymentTable = props => {
  const { header, data } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.name1}</td>
              <td>{d?.name2}</td>
              <td>{d?.name3}</td>
              <td>{d?.name4}</td>
            </tr>
          ))}
        </table>
      </div>
      {/* <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
          //   onChange={handlePageChange}
        />
      </div> */}
    </>
  );
};

export const UsersTable = props => {
  const { header, data } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.name}</td>
              <td>{d?.email}</td>
              <td>{d?.profile}</td>
              <td>{d?.status}</td>
              <td>{d?.createdAt}</td>
              <td>{d?.editedAt}</td>
              <td>
                <div className="table-buttons-conatiner">
                  <TableActionButton src={view} />
                  <TableActionButton src={editLogo} />
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetsTable = props => {
  const { header, data } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.transport}</td>
              <td>{d?.model}</td>
              <td>{d?.brand}</td>
              <td>{d?.plate}</td>
              <td>{d?.year}</td>
              <td>{d?.purchaseDate}</td>
              <td>
                <div className="table-buttons-conatiner">
                  <TableActionButton src={view} />
                  <TableActionButton src={editLogo} />
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ExportablesDBTable = props => {
  const { header, data, handlePageChange, type, tableRef } = props;

  let displayPayment = '';
  return (
    <>
      <div className="table-container">
        <table className="table exportable-table">
          <tr>
            {header?.map((h, i) => (
              <th key={h?.name + i} style={{ display: h?.hide ? 'none' : '' }}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {!type &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => (
              <tr key={d?.caseId}>
                <td>
                  {d?.user?.firstName} {d?.user?.lastName}
                </td>
                <td>{d?.caseId}</td>
                <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                <td>{moment.utc(d?.createdAt).format('LTS')}</td>
                <td>{d?.status}</td>
                <td>
                  {capitalize(d?.particularCustomer?.firstName) ||
                    capitalize(d?.membership?.customer?.firstName)}
                </td>
                <td>
                  {capitalize(d?.particularCustomer?.lastName) ||
                    capitalize(d?.membership?.customer?.lastName)}
                </td>{' '}
                <td>
                  {exists(d?.particularCustomer?.vehiclePlate) ||
                    exists(d?.membership?.vehicle?.plate)}
                </td>
                <td>
                  {capitalize(d?.particularCustomer?.vehicleBrand?.brand) ||
                    capitalize(d?.membership?.vehicle?.brand)}
                </td>{' '}
                <td>
                  {capitalize(d?.particularCustomer?.vehicleModel?.model) ||
                    capitalize(d?.membership?.vehicle?.model)}
                </td>{' '}
                <td>
                  {exists(d?.particularCustomer?.vehicleYear) ||
                    exists(d?.membership?.vehicle?.year)}
                </td>{' '}
                <td>{capitalize(d?.membership?.code)}</td>
                <td>{capitalize(d?.membership?.status)}</td>
                <td>{capitalize(d?.caseType)}</td>
                <td>{capitalize(d?.fault)}</td>
                <td>{capitalize(d?.realFault)}</td>
                <td>{capitalize(d?.originState)}</td>
                <td>{capitalize(d?.originCity)}</td>
                <td>{capitalize(d?.destinyState)}</td>
                <td>{capitalize(d?.destinyCity)}</td>
                {d?.services?.map((service, j) => {
                  return (
                    <>
                      <td style={{ display: displayPayment }}>
                        {serviceName(service?.productCode)}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.code}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.provider?.isInternal === true && 'Propia'}
                        {service?.provider?.isInternal === false && 'Externa'}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.provider?.contactName}
                      </td>{' '}
                      <td style={{ display: displayPayment }}>
                        {service?.shift}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.price ? service?.price + '$' : ''}{' '}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.pvp ? service?.pvp + '$' : ''}{' '}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.paymentStatus}
                      </td>
                      {service?.payments?.map((payment, k) => {
                        return (
                          <>
                            <td style={{ display: displayPayment }}>
                              {capitalize(payment?.method)}
                            </td>
                            <td style={{ display: displayPayment }}>
                              {payment?.totalAmount
                                ? payment?.totalAmount + '$'
                                : ''}
                            </td>
                            <td style={{ display: displayPayment }}>
                              {payment?.reference}
                            </td>
                            <td style={{ display: displayPayment }}>
                              {payment?.referenceImg && (
                                <a
                                  target="_blank"
                                  href={payment?.referenceImg}
                                  rel="noreferrer"
                                >
                                  Comprobante de pago
                                </a>
                              )}
                            </td>
                          </>
                        );
                      })}
                    </>
                  );
                })}
                {d?.newStages?.map((stage, i) => {
                  return <td>{reasignTime(d, i)}</td>;
                })}
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.avImage) {
                      return (
                        <a
                          target="_blank"
                          href={service?.avImage}
                          rel="noreferrer"
                        >
                          Imagen AV
                        </a>
                      );
                    }
                  })}
                </td>
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.towImages?.towAtOrigin) {
                      return (
                        <a
                          target="_blank"
                          href={service?.towImages?.towAtOrigin}
                          rel="noreferrer"
                        >
                          Gruero en origen
                        </a>
                      );
                    }
                  })}
                </td>
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.towImages?.vehicleOnTow) {
                      return (
                        <a
                          target="_blank"
                          href={service?.towImages?.vehicleOnTow}
                          rel="noreferrer"
                        >
                          Vehículo en grúa
                        </a>
                      );
                    }
                  })}
                </td>
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.towImages?.vehicleOnRoad) {
                      return (
                        <a
                          target="_blank"
                          href={service?.towImages?.vehicleOnRoad}
                          rel="noreferrer"
                        >
                          Vehículo en destino
                        </a>
                      );
                    }
                  })}
                </td>
              </tr>
            ))}
        </table>

        <PrintBDTable
          data={data}
          header={header}
          displayPayment={''}
          type={type}
          tableRef={tableRef}
        />
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const PaymentType = props => {
  const { payment, membership } = props;

  let quotaCounter = 0;
  let paymentCounter = 0;

  // Return Quota and Payment Number if Financed payment
  for (let i = 0; i < membership?.paymentDues?.length; i++) {
    if (membership?.paymentDues[i]) {
      quotaCounter = quotaCounter + 1;

      for (let j = 0; j < membership?.paymentDues[i]?.payment?.length; j++) {
        paymentCounter = paymentCounter + 1;

        if (payment?._id === membership?.paymentDues[i]?.payment[j]) {
          return `Cuota ${i + 1} - Pago ${j + 1} `;
        }
      }
    }
  }
  // Pago upgrade
  if (payment?.upgrade) {
    return `Pago Upgrade`;
  }
  // Membership payment
  for (let k = 0; k < membership?.payments.length; k++) {
    if (payment?._id === membership?.payments[k]?._id) {
      return `Pago ${k + 1} `;
    }
  }
};

export const PaymentsTable = props => {
  const { role, membership, header, data, handleEditPaymentModal } = props;

  const validatePaymentButton = (
    role,
    handleEditPaymentModal,
    d,
    membership
  ) => {
    if (
      membership?.membershipStatus === planStatus?.PENDING_FOR_APPROBAL &&
      [
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ]?.includes(role) &&
      authService?.getUser()?.type !== userType?.EXTERNO
    ) {
      return (
        <ActionButton label="Editar" action={() => handleEditPaymentModal(d)} />
      );
    }

    if (
      membership?.membershipStatus === planStatus?.PENDING_FOR_APPROBAL &&
      [
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ]?.includes(role)
    ) {
      return (
        <ActionButton label="Editar" action={() => handleEditPaymentModal(d)} />
      );
    }

    if (
      ![
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ]?.includes(role)
    )
      return (
        <ActionButton label="Editar" action={() => handleEditPaymentModal(d)} />
      );
  };

  const calculateTotal = (payment, type) => {
    let total = 0;

    for (let i = 0; i < payment?.length; i++) {
      total = total + payment[i][type];
    }
    return total?.toFixed(2);
  };

  if (membership?.payments?.length === 0) {
    return null;
  } else {
    return (
      <>
        <h6 style={{ marginTop: '12px' }}>Pagos Realizados</h6>
        <div className="table-container">
          <table className="table payments-table">
            <tr>
              {header?.map((h, i) => (
                <th
                  key={h?.name + i}
                  style={{ display: h?.hide ? 'none' : '' }}
                >
                  {h?.name}
                </th>
              ))}
            </tr>
            {membership?.payments.length > 0 &&
              !data?.isLoading &&
              membership?.payments?.map((d, i) => (
                <tr key={d?.caseId}>
                  <td>
                    <PaymentType payment={d} membership={membership} />
                    {/* Pago {i + 1} */}
                  </td>
                  <td>{d?.totalAmount?.toFixed(2)}</td>
                  <td>{d?.totalAmountInBsS?.toFixed(2)}</td>
                  <td>{capitalize(d?.method)}</td>
                  <td>{d?.status}</td>
                  <td>{d?.reference}</td>
                  <td>
                    {d?.referenceImg && (
                      <a
                        style={{ padding: '0px' }}
                        target="_blank"
                        href={d?.referenceImg}
                        rel="noreferrer"
                      >
                        Ver Comprobante
                      </a>
                    )}
                  </td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {validatePaymentButton(
                      role,
                      handleEditPaymentModal,
                      d,
                      membership
                    )}
                  </td>
                </tr>
              ))}
            <tr>
              <td>
                <b style={{ fontSize: '14px' }}>Total</b>
              </td>
              <td>
                <b style={{ fontSize: '14px' }}>
                  {calculateTotal(membership?.payments, 'totalAmount')}
                </b>
              </td>
              <td>
                <b style={{ fontSize: '14px' }}>
                  {calculateTotal(membership?.payments, 'totalAmountInBsS')}
                </b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </>
    );
  }
};

export const PendingPaymentsTable = props => {
  const {
    membership,
    header,
    data,
    dollarRate,
    openMembershipPendingPaymentModal,
  } = props;

  const checkIfPending = pending => {
    let paid = 0;
    for (let i = 0; i < pending.length; i++) {
      if (pending[i]?.paymentDate) {
        paid = paid + 1;
      }
    }
    if (paid === pending.length) {
      return true;
    } else return false;
  };

  if (
    membership?.paymentDues?.length === 0 ||
    checkIfPending(membership?.paymentDues)
  ) {
    return null;
  } else {
    return (
      <>
        <h6 style={{ marginTop: '12px' }}>Pagos Pendientes</h6>
        <div className="table-container">
          <table className="table payments-table">
            <tr>
              {header?.map((h, i) => (
                <th
                  key={h?.name + i}
                  style={{ display: h?.hide ? 'none' : '' }}
                >
                  {h?.name}
                </th>
              ))}
            </tr>
            {membership?.paymentDues?.length > 0 &&
              !data?.isLoading &&
              membership?.paymentDues?.map((d, i) => {
                if (!d?.paymentDate)
                  return (
                    <tr key={d?.caseId}>
                      <td>Cuota {i + 1}</td>
                      <td>{d?.amount?.toFixed(2)}</td>
                      <td>{(d?.amount * dollarRate)?.toFixed(2)}</td>
                      <td>Cuenta por cobrar</td>
                      <td>
                        {d?.dueDate
                          ? moment.utc(d?.dueDate).format('DD/MM/YYYY')
                          : ''}
                      </td>
                      <td>
                        <ActionButton
                          label="Realizar pago pendiente"
                          action={() => openMembershipPendingPaymentModal(i)}
                        />
                      </td>
                    </tr>
                  );
              })}
          </table>
        </div>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </>
    );
  }
};

export const PrintBDTable = props => {
  const { data, header, displayPayment, type, tableRef } = props;

  let newHeader = header?.filter(h => !h?.hidePrint);

  return (
    <table
      style={{ display: 'none' }}
      ref={tableRef}
      className="table exportable-table"
    >
      <tr>
        {newHeader?.map((h, i) => (
          <th key={h?.name + i} style={{ display: h?.hide ? 'none' : '' }}>
            {h?.name}
            {h?.sortable && (
              <img
                style={{ cursor: 'pointer' }}
                src={navOptionArrow}
                alt="immage"
              ></img>
            )}
          </th>
        ))}
      </tr>
      {!type &&
        data?.data.length > 0 &&
        !data?.isLoading &&
        data?.data?.map((d, i) => (
          <tr key={d?.caseId}>
            <td>
              {d?.user?.firstName} {d?.user?.lastName}
            </td>
            <td>{d?.caseId}</td>
            <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
            <td>{moment.utc(d?.createdAt).format('LTS')}</td>
            <td>{d?.status}</td>
            <td>
              {capitalize(d?.particularCustomer?.firstName) ||
                capitalize(d?.membership?.customer?.firstName)}
            </td>
            <td>
              {capitalize(d?.particularCustomer?.lastName) ||
                capitalize(d?.membership?.customer?.lastName)}
            </td>{' '}
            <td>
              {exists(d?.particularCustomer?.vehiclePlate) ||
                exists(d?.membership?.vehicle?.plate)}
            </td>
            <td>
              {capitalize(d?.particularCustomer?.vehicleBrand?.brand) ||
                capitalize(d?.membership?.vehicle?.brand)}
            </td>{' '}
            <td>
              {capitalize(d?.particularCustomer?.vehicleModel?.model) ||
                capitalize(d?.membership?.vehicle?.model)}
            </td>{' '}
            <td>
              {exists(d?.particularCustomer?.vehicleYear) ||
                exists(d?.membership?.vehicle?.year)}
            </td>{' '}
            <td>{capitalize(d?.membership?.code)}</td>
            <td>{capitalize(d?.membership?.status)}</td>
            <td>{capitalize(d?.caseType)}</td>
            <td>{capitalize(d?.fault)}</td>
            <td>{capitalize(d?.realFault)}</td>
            <td>{capitalize(d?.originState)}</td>
            <td>{capitalize(d?.originCity)}</td>
            <td>{capitalize(d?.destinyState)}</td>
            <td>{capitalize(d?.destinyCity)}</td>
            {d?.services?.map((service, j) => {
              return (
                <>
                  <td style={{ display: displayPayment }}>
                    {serviceName(service?.productCode)}
                  </td>
                  <td style={{ display: displayPayment }}>{service?.code}</td>
                  <td style={{ display: displayPayment }}>
                    {service?.provider?.isInternal === true && 'Propia'}
                    {service?.provider?.isInternal === false && 'Externa'}
                  </td>
                  <td style={{ display: displayPayment }}>
                    {service?.provider?.contactName}
                  </td>
                  <td style={{ display: displayPayment }}>{service?.shift}</td>
                  <td style={{ display: displayPayment }}>
                    {service?.price ? service?.price + '$' : ''}{' '}
                  </td>
                  <td style={{ display: displayPayment }}>
                    {service?.pvp ? service?.pvp + '$' : ''}{' '}
                  </td>
                  <td style={{ display: displayPayment }}>
                    {service?.paymentStatus}
                  </td>
                  {service?.payments?.map((payment, k) => {
                    return (
                      <>
                        <td style={{ display: displayPayment }}>
                          {capitalize(payment?.method)}
                        </td>
                        <td style={{ display: displayPayment }}>
                          {payment?.totalAmount
                            ? payment?.totalAmount + '$'
                            : ''}
                        </td>
                        <td style={{ display: displayPayment }}>
                          {payment?.reference}
                        </td>
                      </>
                    );
                  })}
                </>
              );
            })}
            {d?.newStages?.map((stage, i) => {
              return <td>{reasignTime(d, i)}</td>;
            })}
          </tr>
        ))}
    </table>
  );
};

export const ExportablesPaymentTable = props => {
  const { header, data, handlePageChange, type, tableRef } = props;
  return (
    <>
      <div className="table-container">
        <table ref={tableRef} className="table exportable-table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {[
            exportablesTypes?.PAYMENT_AV,
            exportablesTypes?.PAYMETN_INTERNAL_TOW,
            exportablesTypes?.PAYMENT_EXTERNAL_TOW,
          ].includes(type) &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.providerName)}</td>
                <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                <td>{capitalize(d?.product)}</td>
                <td>{capitalize(d?.caseType)}</td>
                <td>{exists(d?.code)}</td>
                <td>{exists(d?.plate)}</td>
                <td>{capitalize(d?.vehicleBrand)}</td>
                <td>{capitalize(d?.originState)}</td>
                <td>{capitalize(d?.originCity)}</td>
                <td>{exists(d?.price?.toFixed(2))}$</td>
                <td>{exists(d?.providerPay?.toFixed(2))}$</td>
              </tr>
            ))}
        </table>
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ProvidersTable = props => {
  const {
    externalHeader,
    internalHeader,
    data,
    assignTow,
    handlePageChange,
    internal,
  } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {internal ? (
              <>
                {internalHeader.map(h => (
                  <th key={h?.value}>
                    {h?.name}
                    {h?.sortable && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={navOptionArrow}
                      ></img>
                    )}
                  </th>
                ))}
              </>
            ) : (
              <>
                {externalHeader.map(h => (
                  <th key={h?.value}>
                    {h?.name}
                    {h?.sortable && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={navOptionArrow}
                        alt="immage"
                      ></img>
                    )}
                  </th>
                ))}
              </>
            )}
          </tr>
          {data?.data?.map(d => (
            <tr key={d?._id}>
              <td>
                <img
                  onClick={() => assignTow(d)}
                  style={{ cursor: 'pointer' }}
                  src={add}
                  alt="add"
                />
              </td>
              {!internal && <td>{capitalize(d?.companyName)}</td>}
              <td>{capitalize(d?.contactName)}</td>
              <td>{exists(d?.phonePrefix) + '-' + exists(d?.phoneNumber)}</td>
              <td>
                {exists(d?.identificationType) +
                  ': ' +
                  exists(d?.identification)}
              </td>

              <td>{capitalize(d?.state)}</td>
              <td>{d?.isActive}</td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const InternalProviderTable = props => {
  const { header, dataTable, handlePageChange, edit } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img
                    style={{ cursor: 'pointer' }}
                    src={navOptionArrow}
                    alt="immage"
                  ></img>
                )}
              </th>
            ))}
          </tr>
          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.contactName)}</td>
                <td>{capitalize(d?.type)}</td>
                <td>{exists(d?.phonePrefix) + '-' + exists(d?.phoneNumber)}</td>
                <td>
                  {exists(d?.identificationType) +
                    ': ' +
                    exists(d?.identification)}
                </td>
                <td>{capitalize(d?.state)}</td>
                <td>{exists(d?.isActive)}</td>
                <td>
                  <div className="table-buttons-conatiner">
                    {/* <TableActionButton src={view} /> */}
                    <TableActionButton src={editLogo} action={() => edit(d)} />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const VehicularAssetsTable = props => {
  const { header, dataTable, handlePageChange, edit } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img
                    style={{ cursor: 'pointer' }}
                    src={navOptionArrow}
                    alt="immage"
                  ></img>
                )}
              </th>
            ))}
          </tr>
          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.class)}</td>
                <td>{capitalize(d?.brand)}</td>
                <td>{capitalize(d?.model)}</td>
                <td>{capitalize(d?.color)}</td>
                <td>{exists(d?.plate)}</td>
                <td>{exists(d?.year)}</td>
                <td>
                  {d?.purchaseDate
                    ? moment.utc(d?.purchaseDate).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td>{exists(d?.description)}</td>
                <td>
                  <div className="table-buttons-conatiner">
                    {/* <TableActionButton src={view} /> */}
                    <TableActionButton src={editLogo} action={() => edit(d)} />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const IncidenceTable = props => {
  const { header, data, title } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="incidence-table table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>
          {data?.map(d => (
            <tr key={d?._id}>
              <td>
                {d?.createdAt
                  ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>{capitalize(d?.fault)}</td>
              <td>{capitalize(d?.service)}</td>
              <td>{d?.serviceKm ? d?.serviceKm + ' Km' : 'N/A'}</td>
            </tr>
          ))}
        </table>
        {data?.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </>
  );
};

export const FollowingTable = props => {
  const { header, data, title } = props;

  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="incidence-table table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>
          {data?.map(d => (
            <tr key={d?._id}>
              <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
              <td>{moment.utc(d?.createdAt).format('LTS')}</td>
              <td>{exists(d?.stage)}</td>
              <td>{d?.type}</td>
              <td>{exists(d?.message)}</td>
              <td>{exists(d?.note)}</td>
            </tr>
          ))}
        </table>
        {data?.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </div>
  );
};

export const OperationsUserTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    tableRef,
    handleEditFleetForm,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {d?.firstName} {d?.lastName}
                  </td>
                  <td>{d?.email}</td>
                  <td>{transcriptRoles(d?.role)}</td>
                  <td>{d?.status}</td>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{moment.utc(d?.updatedAt).format('DD/MM/YYYY')}</td>

                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditFleetForm(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

// export const SalesUserTable = (props) => {
//   const {
//     header,
//     data,
//     title,
//     handlePageChange,
//     openDetail,
//     tableRef,
//     handleEditFleetForm,
//   } = props;

//   return (
//     <>
//       <div className="filter-button-container"></div>
//       <div className="title-center-component">
//         <h5>{title}</h5>
//       </div>
//       <div className="table-container">
//         <table ref={tableRef} className="table">
//           <tr>
//             {header?.map((h) => (
//               <th key={h?.name}>{h?.name}</th>
//             ))}
//           </tr>
//           {data?.data?.length > 0 &&
//             !data?.isLoading &&
//             data?.data?.map((d) => {
//               return (
//                 <tr key={d?._id}>
//                   <td>
//                     {d?.firstName} {d?.lastName}
//                   </td>
//                   <td>{d?.email}</td>
//                   <td>{transcriptRoles(d?.role)}</td>
//                   <td>{d?.status}</td>
//                   <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
//                   <td>{moment.utc(d?.updatedAt).format('DD/MM/YYYY')}</td>

//                   <td>
//                     <div className="table-buttons-conatiner">
//                       <TableActionButton
//                         src={editLogo}
//                         action={() => handleEditFleetForm(d)}
//                       />
//                     </div>
//                   </td>
//                 </tr>
//               );
//             })}
//         </table>
//         {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
//         {data?.data.length <= 0 && !data?.isLoading && (
//           <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
//         )}
//       </div>
//       <div className="pagination-container">
//         <Pagination
//           count={Math.ceil(data?.count / data?.limit)}
//           variant="outlined"
//           shape="rounded"
//           page={data?.page}
//           onChange={handlePageChange}
//         />
//       </div>
//     </>
//   );
// };

export const TrazeTable = props => {
  const { header, data, title } = props;

  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="incidence-table table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>
          {data?.map(d => (
            <tr key={d?._id}>
              <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
              <td>{moment.utc(d?.createdAt).format('LTS')}</td>
              <td>{d?.stage?.stage}</td>
              <td>{d?.estimatedTime}</td>
            </tr>
          ))}
        </table>
        {data?.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </div>
  );
};

export const SalesMembershipContentTable = props => {
  const {
    data,
    redirectToOrderDetail,
    cuentasPorCobrar,
    statusCuentasPorCobrar,
    print,
  } = props;

  const upgradePayments = (data, currency) => {
    for (let i = 0; i < data?.payments?.length; i++) {
      if (data?.payments[i]?.upgrade && currency === 'USD') {
        return data?.payments[i]?.totalAmount?.toFixed(2);
      }
      if (data?.payments[i]?.upgrade && currency === 'BsS') {
        return data?.payments[i]?.totalAmountInBsS?.toFixed(2);
      }
    }
  };

  const PaymentColumns = props => {
    const { data, index } = props;

    return (
      <>
        <td>
          {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
            data?.paymentType
          ) &&
            // data?.paymentType === paymentType?.DE_CONTADO &&
            !data?.payments[index]?.upgrade
            ? capitalize(data?.payments[index]?.method)
            : ''}
        </td>
        <td>
          {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
            data?.paymentType
          ) &&
            data?.payments &&
            !data?.payments[index]?.upgrade
            ? data?.payments[index]?.totalAmount
            : ''}
        </td>
        <td>
          {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
            data?.paymentType
          ) &&
            data?.payments &&
            !data?.payments[index]?.upgrade
            ? data?.payments[index]?.totalAmountInBsS?.toFixed(2)
            : ''}
        </td>
        <td>
          {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
            data?.paymentType
          ) &&
            data?.payments &&
            !data?.payments[index]?.upgrade
            ? data?.payments[index]?.reference
            : ''}
        </td>
      </>
    );
  };

  const Duepayments = props => {
    const { data, index } = props;

    let pagos = [];

    let maxPayments = 2;

    if (index > 1) {
      maxPayments = 1;
    }

    for (let i = 0; i < maxPayments; i++) {
      pagos?.push({
        totalAmount:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.totalAmount
            ? data?.paymentDues[index]?.paymentDetails[i]?.totalAmount
            : '',
        totalAmountInBsS:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.totalAmountInBsS
            ? data?.paymentDues[index]?.paymentDetails[i]?.totalAmountInBsS
            : '',
        method:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.method
            ? data?.paymentDues[index]?.paymentDetails[i]?.method
            : '',
        reference:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.reference
            ? data?.paymentDues[index]?.paymentDetails[i]?.reference
            : '',
      });
    }

    return (
      <>
        {pagos?.map(p => (
          <DuepaymentsColumn key={p?._id} payment={p} />
        ))}
      </>
    );
  };

  return (
    <>
      {data?.data?.length > 0 &&
        !data?.isLoading &&
        data?.data?.map(d => {
          return (
            <tr
              key={d?._id}
              style={{ backgroundColor: checkMissingVehicleFields(d) }}
            // onClick={() => console.log("A", d)}
            >
              {!print ? (
                <td>
                  <div className="table-buttons-conatiner">
                    <TableActionButton
                      src={searchLogo}
                      action={() => redirectToOrderDetail(d)}
                    />
                    <a href={`${editURL}/${d?._id}`}>
                      <div className="table-action-button">
                        <img target="_blank" src={editLogo} alt="editLogo" />
                      </div>
                    </a>
                  </div>
                </td>
              ) : null}
              <td>{d?.membershipId}</td>
              <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
              <td>
                {d?.seller?.firstName} {d?.seller?.lastName}
              </td>
              <td>{d?.saleChannel}</td>
              <td>{capitalize(d?.membershipCode)}</td>
              <td>{capitalize(d?.membershipStatus)}</td>
              <td>
                {capitalize(
                  d?.customer?.firstName + ' ' + d?.customer?.lastName
                )}
              </td>
              <td>
                {d?.customer?.identificationType}-{d?.customer?.identification}
              </td>
              <td>{d?.customer?.gender}</td>
              <td>
                {d?.customer?.phonePrefix}-{d?.customer?.phoneNumber}
              </td>
              <td>{d?.vehicle?.plate}</td>
              <td>{capitalize(d?.vehicle?.class)}</td>
              <td>{d?.vehicle?.year}</td>
              <td>{d?.discountCode ? d?.discountCode : d?.giftcardCode}</td>
              <td>{d?.paymentType}</td>
              <td>{d?.paymentStrategy}</td>
              <td>{d?.rcv}</td>
              <td>{d?.totalBill?.toFixed(2)}</td>
              <td>{d?.totalBillBsS?.toFixed(2)}</td>
              <PaymentColumns data={d} index={0} />
              <PaymentColumns data={d} index={1} />
              <td>
                {[paymentType?.FINANCIADO, paymentType?.CASHEA]?.includes(
                  d?.paymentType
                ) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[0]?.dueDate
                  ? moment.utc(d?.paymentDues[0]?.dueDate).format('DD/MM/YYYY')
                  : ''}
              </td>
              <Duepayments data={d} index={0} />
              <td>
                {[paymentType?.FINANCIADO, paymentType?.CASHEA]?.includes(
                  d?.paymentType
                ) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[1]?.dueDate
                  ? moment.utc(d?.paymentDues[1]?.dueDate).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>
                {[paymentType?.FINANCIADO, paymentType?.CASHEA]?.includes(
                  d?.paymentType
                ) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[1]?.paymentDate
                  ? moment
                    .utc(d?.paymentDues[1]?.paymentDate)
                    .format('DD/MM/YYYY')
                  : ''}
              </td>
              <Duepayments data={d} index={1} />
              <td>
                {[paymentType?.CASHEA]?.includes(d?.paymentType) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[2]?.dueDate
                  ? moment.utc(d?.paymentDues[2]?.dueDate).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>
                {[paymentType?.CASHEA]?.includes(d?.paymentType) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[2]?.paymentDate
                  ? moment
                    .utc(d?.paymentDues[2]?.paymentDate)
                    .format('DD/MM/YYYY')
                  : ''}
              </td>
              <Duepayments data={d} index={2} />
              <td>
                {[paymentType?.CASHEA]?.includes(d?.paymentType) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[3]?.dueDate
                  ? moment.utc(d?.paymentDues[3]?.dueDate).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>
                {[paymentType?.CASHEA]?.includes(d?.paymentType) &&
                  !d?.payments[0]?.upgrade &&
                  d?.paymentDues[3]?.paymentDate
                  ? moment
                    .utc(d?.paymentDues[3]?.paymentDate)
                    .format('DD/MM/YYYY')
                  : ''}
              </td>
              <Duepayments data={d} index={3} />
              <td>
                {d?.paymentStatus !== 'Pago realizado' && cuentasPorCobrar(d)}
              </td>
              <td>{statusCuentasPorCobrar(d)}</td>
              <td>{capitalize(d?.vehicle?.state)}</td>
              <td>{capitalize(d?.vehicle?.city)}</td>
              <td>{d?.contractType}</td>
              <td>{d?.customer?.email?.toLowerCase()}</td>
              <td>{moment.utc(d?.customer?.birthday).format('DD/MM/YYYY')}</td>
              <td>{capitalize(d?.vehicle?.brand)}</td>
              <td>{capitalize(d?.vehicle?.model)}</td>
              <td>
                {d?.assignedAdvisor?.firstName} {'  '}
                {d?.assignedAdvisor?.lastName}
              </td>
              <td>{d?.renewalsCount}</td>
              <td>
                {d?.rcvBeginDate
                  ? moment.utc(d?.rcvBeginDate).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>
                {d?.rcvEndDate
                  ? moment.utc(d?.rcvEndDate).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>{moment.utc(d?.beginDate).format('DD/MM/YYYY')}</td>
              <td>{moment.utc(d?.endDate).format('DD/MM/YYYY')}</td>
              <td>{capitalize(d?.accidentRate)}</td>
              <td>{capitalize(d?.affiliationType)}</td>
              <td>{d?.upgrades?.length > 0 ? 'Si' : 'No'}</td>
              <td>{upgradePayments(d, 'USD')}</td>
              <td>{upgradePayments(d, 'BsS')}</td>
              <td>{d?.fleetName}</td>
              <td>{d?.contactInCampaign ? 'Contactar' : 'No Contactar'}</td>
            </tr>
          );
        })}
    </>
  );
};

export const NoAvailableResults = props => {
  const { data, dateFrom, dateTo } = props;

  if (data?.data.length > 0) {
    return null;
  }

  if (
    (!data?.searchParam && !dateFrom && !dateTo) ||
    (!data?.data && !data?.isLoading)
  ) {
    return (
      <h6 style={{ textAlign: 'center' }}>
        Puedes hacer la búsqueda de la membresia por: nombre. apellido.
        identificación. placa o código de afiliación.
      </h6>
    );
  }
  if (
    (!data?.isLoading && data?.searchParam) ||
    ((dateFrom || dateTo) && data?.data.length <= 0)
  ) {
    return (
      <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
    );
  } else return null;
};

export const SalesMembershipTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    redirectToOrderDetail,
    tableRef,
    dateFrom,
    dateTo,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th style={{ fontSize: '13px' }} key={h?.name}>
                {h?.name}
              </th>
            ))}
          </tr>
          <SalesMembershipContentTable
            data={data}
            redirectToOrderDetail={redirectToOrderDetail}
            cuentasPorCobrar={cuentasPorCobrar}
            statusCuentasPorCobrar={statusCuentasPorCobrar}
          />
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        <NoAvailableResults data={data} dateFrom={dateFrom} dateTo={dateTo} />
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>

      {/* Table for printing */}
      <div style={{ display: 'none' }} className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => {
              if (h?.hidePrint) {
                return null;
              } else {
                return <th key={h?.name}>{h?.name}</th>;
              }
            })}
          </tr>
          <SalesMembershipContentTable
            data={data}
            print={true}
            redirectToOrderDetail={redirectToOrderDetail}
            cuentasPorCobrar={cuentasPorCobrar}
            statusCuentasPorCobrar={statusCuentasPorCobrar}
          />
        </table>
      </div>
    </>
  );
};

export const RCVsTable = props => {
  const { header, data, title, handlePageChange, tableRef } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.membershipId}</td>
                  <td>{d?.affiliationType}</td>
                  <td>{d?.totalPremium ? d?.totalPremium + '$' : ''}</td>
                  <td>
                    {d?.rcvBeginDate
                      ? moment.utc(d?.rcvBeginDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {d?.rcvEndDate
                      ? moment.utc(d?.rcvEndDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {capitalize(d?.customer[0]?.firstName) +
                      ' ' +
                      capitalize(d?.customer[0]?.lastName)}
                  </td>
                  <td>
                    {capitalize(d?.customer[0]?.identificationType) +
                      '-' +
                      d?.customer[0]?.identification}
                  </td>
                  <td>
                    {capitalize(d?.customer[0]?.phonePrefix) +
                      '-' +
                      capitalize(d?.customer[0]?.phoneNumber)}
                  </td>
                  <td style={{ textTransform: 'lowercase' }}>
                    {capitalize(d?.customer[0]?.email)}
                  </td>
                  <td>
                    {d?.customer[0]?.birthday
                      ? moment
                        .utc(d?.customer[0]?.birthday)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td> {capitalize(d?.vehicle[0]?.state)}</td>
                  <td> {capitalize(d?.vehicle[0]?.city)}</td>
                  <td> {exists(d?.vehicle[0]?.plate)}</td>
                  <td> {capitalize(d?.vehicle[0]?.class)}</td>
                  <td> {capitalize(d?.vehicle[0]?.type)}</td>
                  <td> {capitalize(d?.vehicle[0]?.brand)}</td>
                  <td> {capitalize(d?.vehicle[0]?.model)}</td>
                  <td> {capitalize(d?.vehicle[0]?.color)}</td>
                  <td> {capitalize(d?.vehicle[0]?.year)}</td>
                  <td> {capitalize(d?.vehicle[0]?.bodySerial)}</td>
                </tr>
              );
            })}
        </table>
        {/* {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />} */}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const RCV_CSTable = props => {
  const { header, data, title, handlePageChange, tableRef } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.customer[0]?.identificationType}</td>
                  <td>{d?.customer[0]?.identification}</td>
                  <td>{d?.customer[0]?.firstName}</td>
                  <td>{d?.customer[0]?.lastName}</td>
                  <td>{d?.customer[0]?.gender}</td>
                  <td>{d?.customer[0]?.maritalStatus}</td>
                  <td>
                    {moment.utc(d?.customer[0]?.birthday)?.format('YYYY/MM/DD')}
                  </td>
                  <td>{capitalize(d?.customer[0]?.profession)}</td>
                  <td>{capitalize(d?.customer[0]?.economicActivity)}</td>
                  <td>{d?.customer[0]?.phoneNumber}</td>
                  <td>{capitalize(d?.vehicle[0]?.address)}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{capitalize(d?.vehicle[0]?.municipality)}</td>
                  <td>
                    {d?.vehicle[0]?.ownerCity
                      ? capitalize(d?.vehicle[0]?.ownerCity)
                      : capitalize(d?.vehicle[0]?.city)}
                  </td>
                  <td>
                    {d?.vehicle[0]?.ownerState
                      ? capitalize(d?.vehicle[0]?.ownerState)
                      : capitalize(d?.vehicle[0]?.state)}
                  </td>
                  <td>{d?.customer[0]?.email?.toLowerCase()}</td>
                  <td>{d?.customer[0]?.identificationType}</td>
                  <td>{d?.customer[0]?.identification}</td>
                  <td>{capitalize(d?.customer[0]?.firstName)}</td>
                  <td>{capitalize(d?.customer[0]?.lastName)}</td>
                  <td>
                    {d?.vehicle[0]?.ownerIdentification
                      ? d?.vehicle[0]?.ownerIdentificationType
                      : d?.customer[0]?.identificationType}
                  </td>
                  <td>
                    {d?.vehicle[0]?.ownerIdentification
                      ? d?.vehicle[0]?.ownerIdentification
                      : d?.customer[0]?.identification}
                  </td>
                  <td>
                    {d?.vehicle[0]?.ownerFirstName
                      ? capitalize(d?.vehicle[0]?.ownerFirstName)
                      : capitalize(d?.customer[0]?.firstName)}
                  </td>
                  <td>
                    {d?.vehicle[0]?.ownerLastName
                      ? capitalize(d?.vehicle[0]?.ownerLastName)
                      : capitalize(d?.customer[0]?.lastName)}
                  </td>
                  <td>{capitalize(d?.vehicle[0]?.brand)}</td>
                  <td>{capitalize(d?.vehicle[0]?.model)}</td>
                  <td>{d?.vehicle[0]?.year}</td>
                  <td>{d?.vehicle[0]?.plate}</td>
                  <td>{d?.vehicle[0]?.color}</td>
                  <td>{d?.vehicle[0]?.bodySerial}</td>
                  <td>{d?.vehicle[0]?.engineSerial}</td>
                  <td>{capitalize(d?.vehicle[0]?.type)}</td>
                  <td></td>
                  <td>{d?.vehicle[0]?.seats}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{d?.rcvId}</td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt)?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td>
                    {d?.rcvBeginDate
                      ? moment.utc(d?.rcvBeginDate)?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td>
                    {d?.rcvEndDate
                      ? moment.utc(d?.rcvEndDate)?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{d?.totalPremium?.toFixed(2)}</td>
                  <td>{capitalize(d?.rcv)}</td>
                  <td></td>
                  <td></td>
                  <td>{d?.rcvReceipt}</td>
                  <td></td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt)?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td>
                    {d?.rcvBeginDate
                      ? moment.utc(d?.rcvBeginDate)?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td>
                    {d?.rcvEndDate
                      ? moment.utc(d?.rcvEndDate)?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td>{d?.totalPremium?.toFixed(2)}</td>
                  <td></td>
                  <td></td>
                  <td>{d?.priceInfo?.exchangeRate?.toFixed(2)}</td>
                  <td>
                    {d?.priceInfo?.exchangeRateDate
                      ? moment
                        .utc(d?.priceInfo?.exchangeRateDate)
                        ?.format('YYYY/MM/DD')
                      : ''}
                  </td>
                  <td>{d?.statusText}</td>
                  <td>{d?.annullationReason}</td>
                  <td>
                    {d?.customer[0]?.identityDocument ? (
                      <a
                        href={d?.customer[0]?.identityDocument}
                        target="_blank"
                        rel="noreferrer"
                      >{`Cedula`}</a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {d?.customer[0]?.licenses?.length > 0
                      ? d?.customer[0]?.licenses?.map((l, i) => (
                        <>
                          <a
                            href={l}
                            target="_blank"
                            rel="noreferrer"
                          >{`Licencia`}</a>{' '}
                          <br />
                        </>
                      ))
                      : ''}
                  </td>
                  <td>
                    {d?.vehicle[0]?.circulationCard ? (
                      <a
                        href={d?.vehicle[0]?.circulationCard}
                        target="_blank"
                        rel="noreferrer"
                      >{`Carnet Circulacion`}</a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {' '}
                    {d?.documents?.rcv ? (
                      <a
                        href={d?.documents?.rcv}
                        target="_blank"
                        rel="noreferrer"
                      >
                        RCV
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {' '}
                    {d?.documents?.rcvCard ? (
                      <a
                        href={d?.documents?.rcvCard}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Carnet RCV
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {' '}
                    {d?.documents?.rcvForm ? (
                      <a
                        href={d?.documents?.rcvForm}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Solicitud RCV
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td></td>
                </tr>
              );
            })}
        </table>
        {/* {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />} */}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const UpgradeTable = props => {
  const { header, data, title, handlePageChange, tableRef } = props;

  const upgradePayment = data => {
    let payment = '';

    for (let i = 0; i < data?.payments?.length; i++) {
      if (data?.payments[i]?.upgrade) {
        payment = data?.payments[i];
      }
    }
    return payment;
  };

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {d?.upgradeDate
                      ? moment.utc(d?.upgradeDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {capitalize(d?.upgradeSeller?.firstName) +
                      ' ' +
                      capitalize(d?.upgradeSeller?.lastName)}
                  </td>
                  <td>{d?.membershipId}</td>
                  <td>
                    {capitalize(d?.customer?.firstName) +
                      ' ' +
                      capitalize(d?.customer?.lastName)}
                  </td>
                  <td>
                    {capitalize(d?.previousCode)}-
                    {capitalize(d?.membershipCode)}
                  </td>
                  <td>{d?.kms}</td>
                  <td>{d?.vehicle?.plate}</td>
                  <td>
                    {d?.customer?.identificationType +
                      '-' +
                      d?.customer?.identification}
                  </td>
                  <td>{capitalize(upgradePayment(d)?.method)}</td>
                  <td>{upgradePayment(d)?.totalAmount}</td>
                  <td>{upgradePayment(d)?.totalAmountInBsS}</td>
                  <td>{upgradePayment(d)?.reference}</td>
                  <td>{capitalize(d?.vehicle?.state)}</td>
                  <td>{capitalize(d?.vehicle?.city)}</td>
                  <td>{capitalize(d?.vehicle?.year)}</td>
                  <td>{d?.vehicle?.armorType}</td>
                </tr>
              );
            })}
        </table>
        {/* {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />} */}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const SalesTable = props => {
  const { header, data, title, handlePageChange, tableRef } = props;

  const Duepayments = props => {
    const { data, index } = props;

    let pagos = [];

    for (let i = 0; i < 2; i++) {
      pagos?.push({
        totalAmount:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.totalAmount
            ? data?.paymentDues[index]?.paymentDetails[i]?.totalAmount
            : '',
        totalAmountInBsS:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.totalAmountInBsS
            ? data?.paymentDues[index]?.paymentDetails[i]?.totalAmountInBsS
            : '',
        method:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.method
            ? data?.paymentDues[index]?.paymentDetails[i]?.method
            : '',
        reference:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.reference
            ? data?.paymentDues[index]?.paymentDetails[i]?.reference
            : '',
      });
    }

    return (
      <>
        {pagos?.map(p => (
          <DuepaymentsColumn key={p?._id} payment={p} />
        ))}
      </>
    );
  };

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.membershipId}</td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {capitalize(d?.seller?.firstName) +
                      ' ' +
                      capitalize(d?.seller?.lastName)}
                  </td>
                  <td>{d?.saleChannel}</td>
                  <td>{d?.membershipCode}</td>
                  <td>{d?.affiliationType}</td>
                  <td>{d?.previousMembership}</td>
                  <td>
                    {d?.endMonthFromLastMembership
                      ? capitalize(d?.endMonthFromLastMembership)
                      : ''}
                  </td>
                  <td>
                    {' '}
                    {capitalize(d?.customer?.firstName) +
                      ' ' +
                      capitalize(d?.customer?.lastName)}
                  </td>
                  <td> {exists(d?.vehicle?.plate)}</td>
                  <td> {capitalize(d?.vehicle?.year)}</td>
                  <td>{d?.discountCode ? d?.discountCode : d?.giftcardCode}</td>
                  <td>{d?.paymentType}</td>
                  <td>{d?.paymentStrategy}</td>
                  <td>{d?.rcv}</td>
                  <td>{d?.totalBill?.toFixed(2)}</td>
                  <td>{d?.totalBillBsS?.toFixed(2)}</td>
                  {/* Payment Section */}
                  <td>
                    {d?.paymentType === paymentType?.DE_CONTADO
                      ? capitalize(d?.payments[0]?.method)
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[0]?.totalAmount
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[0]?.reference
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.paymentType === paymentType?.DE_CONTADO
                      ? capitalize(d?.payments[1]?.method)
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[1]?.totalAmount
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[1]?.reference
                      : ''}
                  </td>
                  <td>
                    {d?.paymentType === paymentType?.FINANCIADO &&
                      !d?.payments[0]?.upgrade &&
                      d?.paymentDues[0]?.paymentDate
                      ? moment
                        .utc(d?.paymentDues[0]?.paymentDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <Duepayments data={d} index={0} />
                  <td>
                    {d?.paymentType === paymentType?.FINANCIADO &&
                      d?.paymentDues &&
                      d?.paymentDues[1]?.dueDate
                      ? moment
                        .utc(d?.paymentDues[1]?.dueDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {d?.paymentType === paymentType?.FINANCIADO &&
                      d?.paymentDues &&
                      d?.paymentDues[1]?.paymentDate
                      ? moment
                        .utc(d?.paymentDues[1]?.paymentDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <Duepayments data={d} index={1} />
                  <td>
                    {d?.paymentStatus !== 'Pago realizado' &&
                      cuentasPorCobrar(d)}
                  </td>
                  <td>{statusCuentasPorCobrar(d)}</td>
                  <td> {capitalize(d?.vehicle?.state)}</td>
                  <td> {capitalize(d?.vehicle?.city)}</td>
                  <td> {capitalize(d?.vehicle?.model)}</td>
                  <td>
                    {d?.beginDate
                      ? moment.utc(d?.beginDate).format('DD/MM/YYYY')
                      : ''}
                  </td>{' '}
                  <td>
                    {d?.endDate
                      ? moment.utc(d?.endDate).format('DD/MM/YYYY')
                      : ''}
                  </td>{' '}
                  <td>
                    {d?.rcvBeginDate
                      ? moment.utc(d?.rcvBeginDate).format('DD/MM/YYYY')
                      : ''}
                  </td>{' '}
                  <td>
                    {d?.rcvEndDate
                      ? moment.utc(d?.rcvEndDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{capitalize(d?.membershipStatus)}</td>
                </tr>
              );
            })}
        </table>
        {/* {data?.isLoading && <TableLoadSpinner show={data?.isLoading} /> */}

        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ExportableAdminTable = props => {
  const { header, data, title, handlePageChange, tableRef } = props;

  const Duepayments = props => {
    const { data, index } = props;

    let pagos = [];

    for (let i = 0; i < 2; i++) {
      pagos?.push({
        totalAmount:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.totalAmount
            ? data?.paymentDues[index]?.paymentDetails[i]?.totalAmount
            : '',
        totalAmountInBsS:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.totalAmountInBsS
            ? data?.paymentDues[index]?.paymentDetails[i]?.totalAmountInBsS
            : '',
        method:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.method
            ? data?.paymentDues[index]?.paymentDetails[i]?.method
            : '',
        reference:
          data?.paymentDues[index]?.paymentDetails?.length > 0 &&
            data?.paymentDues[index]?.paymentDetails[i]?.reference
            ? data?.paymentDues[index]?.paymentDetails[i]?.reference
            : '',
      });
    }

    return (
      <>
        {pagos?.map(p => (
          <DuepaymentsColumn key={p?._id} payment={p} />
        ))}
      </>
    );
  };

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.membershipId}</td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{capitalize(d?.membershipStatus)}</td>
                  <td>{d?.saleChannel}</td>
                  <td>{capitalize(d?.membershipCode)}</td>
                  <td>{d?.affiliationType}</td>
                  <td>{d?.paymentType}</td>
                  <td>{d?.paymentStrategy}</td>
                  <td>{d?.rcv}</td>
                  <td>{d?.priceInfo?.finalPrice?.toFixed(2)}</td>
                  <td>{d?.priceInfo?.finalPriceInBsS?.toFixed(2)}</td>
                  <td>{d?.totalBill?.toFixed(2)}</td>
                  <td>{d?.totalBillBsS?.toFixed(2)}</td>
                  {/* Payment Section */}
                  <td>
                    {d?.paymentType === paymentType?.DE_CONTADO
                      ? capitalize(d?.payments[0]?.method)
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[0]?.totalAmount
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[0]?.reference
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.paymentType === paymentType?.DE_CONTADO
                      ? capitalize(d?.payments[1]?.method)
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[1]?.totalAmount
                      : ''}
                  </td>
                  <td>
                    {[paymentType?.DE_CONTADO, paymentType?.PENDING]?.includes(
                      d?.paymentType
                    ) && d?.payments
                      ? d?.payments[1]?.reference
                      : ''}
                  </td>
                  <td>
                    {d?.paymentType === paymentType?.FINANCIADO &&
                      !d?.payments[0]?.upgrade &&
                      d?.paymentDues[0]?.paymentDate
                      ? moment
                        .utc(d?.paymentDues[0]?.paymentDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <Duepayments data={d} index={0} />
                  <td>
                    {d?.paymentType === paymentType?.FINANCIADO &&
                      d?.paymentDues &&
                      d?.paymentDues[1]?.dueDate
                      ? moment
                        .utc(d?.paymentDues[1]?.dueDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {d?.paymentType === paymentType?.FINANCIADO &&
                      d?.paymentDues &&
                      d?.paymentDues[1]?.paymentDate
                      ? moment
                        .utc(d?.paymentDues[1]?.paymentDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <Duepayments data={d} index={1} />
                  <td>
                    {d?.paymentStatus !== 'Pago realizado' &&
                      cuentasPorCobrar(d)}
                  </td>
                  <td>{statusCuentasPorCobrar(d)}</td>
                </tr>
              );
            })}
        </table>
        {/* {data?.isLoading && <TableLoadSpinner show={data?.isLoading} /> */}

        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const DueSalesTable = props => {
  const { header, data, title, handlePageChange, tableRef } = props;

  const dollarsPayment = data => {
    if (data?.paymentType === paymentType?.PENDING) {
      let ammount = 0;
      for (let i = 0; i < data?.payments?.length; i++) {
        ammount = ammount + data?.payments[i]?.totalAmount;
      }
      return ammount?.toFixed(2);
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      let ammount = 0;
      for (let i = 0; i < data?.paymentDues[1]?.paymentDetails?.length; i++) {
        ammount =
          ammount + data?.paymentDues[1]?.paymentDetails[i]?.totalAmount;
      }
      return ammount?.toFixed(2);
    }
  };

  const bolivarsPayment = data => {
    if (data?.paymentType === paymentType?.PENDING) {
      let ammount = 0;
      for (let i = 0; i < data?.payments?.length; i++) {
        ammount = ammount + data?.payments[i]?.totalAmountInBsS;
      }
      return ammount?.toFixed(2);
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      let ammount = 0;
      for (let i = 0; i < data?.paymentDues[1]?.paymentDetails?.length; i++) {
        ammount =
          ammount +
          parseFloat(data?.paymentDues[1]?.paymentDetails[i]?.totalAmountInBsS);
      }
      return ammount?.toFixed(2);
    }
  };

  const paymentDate = data => {
    if (data?.paymentType === paymentType?.PENDING) {
      return data?.payments[0]?.createdAt
        ? moment.utc(data?.payments[0]?.createdAt).format('DD/MM/YYYY')
        : '';
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      if (data?.paymentDues?.length > 0 && data?.paymentDues[1]?.paymentDate)
        return moment
          .utc(data?.paymentDues[1]?.paymentDate)
          .format('DD/MM/YYYY');
    }
  };

  const paymentAmount = (data, i) => {
    if (data?.paymentType === paymentType?.PENDING) {
      return data?.payments[0]?.totalAmount
        ? data?.payments[0]?.totalAmount
        : '';
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      if (
        data?.paymentDues?.length > 0 &&
        data?.paymentDues[1]?.paymentDetails[i]?.totalAmount
      )
        return data?.paymentDues[1]?.paymentDetails[i]?.totalAmount;
    }
  };

  const paymentAmountBs = (data, i) => {
    if (data?.paymentType === paymentType?.PENDING) {
      return data?.payments[0]?.totalAmountInBsS
        ? data?.payments[0]?.totalAmountInBsS
        : '';
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      if (
        data?.paymentDues?.length > 0 &&
        data?.paymentDues[1]?.paymentDetails[i]?.totalAmountInBsS
      )
        return data?.paymentDues[1]?.paymentDetails[i]?.totalAmountInBsS;
    }
  };

  const month = data => {
    if (data?.paymentType === paymentType?.PENDING) {
      return getMonthInSpanish(data?.payments[0]?.createdAt);
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      if (data?.paymentDues[1]?.paymentDate)
        return getMonthInSpanish(data?.paymentDues[1]?.paymentDate);
    }
  };

  const method = (data, index0, index1, param) => {
    if (data?.paymentType === paymentType?.PENDING) {
      return data?.payments[index1]?.[param];
    }

    if (data?.paymentType === paymentType?.FINANCIADO) {
      if (data?.paymentDues?.length > 0)
        return data?.paymentDues[index0]?.paymentDetails[index1]?.[param];
    }
  };

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.membershipId}</td>
                  <td>{capitalize(d?.customer)}</td>
                  <td>{capitalize(d?.code)}</td>
                  <td>{capitalize(d?.saleChannel)}</td>
                  <td>{capitalize(d?.affiliationType)}</td>
                  <td>
                    {d?.membershipCreationDate
                      ? moment
                        .utc(d?.membershipCreationDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{capitalize(d?.paymentType)}</td>
                  <td>
                    {d?.paymentDues?.length > 0 && d?.paymentDues[1]?.dueDate
                      ? moment
                        .utc(d?.paymentDues[1]?.dueDate)
                        .format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{dollarsPayment(d)}</td>
                  <td>{bolivarsPayment(d)}</td>
                  <td>{paymentDate(d)}</td>
                  <td>{month(d)}</td>
                  <td>{paymentAmount(d, 0)}</td>
                  <td>{paymentAmountBs(d, 0)}</td>
                  <td>{capitalize(method(d, 1, 0, 'method'))}</td>
                  <td>{method(d, 1, 0, 'reference')}</td>
                  <td>{paymentAmount(d, 1)}</td>
                  <td>{paymentAmountBs(d, 1)}</td>
                  <td>{capitalize(method(d, 1, 1, 'method'))}</td>
                  <td>{method(d, 1, 1, 'reference')}</td>
                </tr>
              );
            })}
        </table>
        {/* {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />
        } */}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const PendinForApprovalContentTable = props => {
  const { data, redirectToOrderDetail, print } = props;
  return (
    <>
      {data?.data?.length > 0 &&
        !data?.isLoading &&
        data?.data?.map(d => {
          return (
            <tr
              key={d?._id}
              style={{ backgroundColor: checkMissingVehicleFields(d) }}
            >
              {!print ? (
                <td>
                  <div className="table-buttons-conatiner">
                    {d?.membershipStatus !==
                      planStatus?.PENDING_FOR_APPROBAL && (
                        <TableActionButton
                          src={searchLogo}
                          action={() => redirectToOrderDetail(d)}
                        />
                      )}
                    <a href={`${editURL}/${d?._id}`}>
                      <div className="table-action-button">
                        <img target="_blank" src={editLogo} alt="editLogo" />
                      </div>
                    </a>
                  </div>
                </td>
              ) : (
                ''
              )}
              <td>{d?.membershipId}</td>
              <td>{capitalize(d?.affiliationType)}</td>
              <td>
                {capitalize(d?.seller?.firstName) +
                  ' ' +
                  capitalize(d?.seller?.lastName)}
              </td>
              <td>{d?.saleChannel}</td>
              <td>
                {capitalize(
                  d?.customer?.firstName + ' ' + d?.customer?.lastName
                )}
              </td>
              <td>
                {d?.customer?.identificationType}-{d?.customer?.identification}
              </td>
              <td>{d?.vehicle?.plate}</td>
              <td>
                {d?.customer?.phonePrefix}-{d?.customer?.phoneNumber}
              </td>
              <td>{d?.verifiedDocuments ? 'Si' : 'No'}</td>
              <td>{capitalize(d?.membershipCode)}</td>
              <td>{d?.verifiedPayment ? 'Si' : 'No'}</td>
              <td>{d?.paymentType}</td>
              <td>{d?.totalBill?.toFixed(2)}</td>
              <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
            </tr>
          );
        })}
    </>
  );
};

export const PendinForApprovalTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    redirectToEditOrder,
    redirectToOrderDetail,
    tableRef,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          <PendinForApprovalContentTable
            data={data}
            redirectToOrderDetail={redirectToOrderDetail}
            redirectToEditOrder={redirectToEditOrder}
          />
        </table>
        <table
          style={{ display: 'none' }}
          ref={tableRef}
          className="sales-afiliation-table table"
        >
          <tr>
            {header?.map(h => {
              if (h?.hidePrint) {
                return null;
              } else {
                return <th key={h?.name}>{h?.name}</th>;
              }
            })}
          </tr>
          <PendinForApprovalContentTable
            data={data}
            print={true}
            redirectToOrderDetail={redirectToOrderDetail}
            redirectToEditOrder={redirectToEditOrder}
          />
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const LostContentTable = props => {
  const { data, redirectToOrderDetail, print } = props;
  return (
    <>
      {data?.data?.length > 0 &&
        !data?.isLoading &&
        data?.data?.map(d => {
          return (
            <tr
              key={d?._id}
              style={{ backgroundColor: checkMissingVehicleFields(d) }}
            >
              {!print ? (
                <td>
                  <div className="table-buttons-conatiner">
                    {d?.membershipStatus !==
                      planStatus?.PENDING_FOR_APPROBAL && (
                        <TableActionButton
                          src={searchLogo}
                          action={() => redirectToOrderDetail(d)}
                        />
                      )}
                    <a href={`${editURL}/${d?._id}`}>
                      <div className="table-action-button">
                        <img target="_blank" src={editLogo} alt="editLogo" />
                      </div>
                    </a>
                  </div>
                </td>
              ) : null}
              <td>{d?.membershipId}</td>
              {/* <td>{capitalize(d?.membershipStatus)}</td> */}
              <td>{capitalize(d?.affiliationType)}</td>
              <td>
                {d?.assignedAdvisor?.firstName} {'  '}
                {d?.assignedAdvisor?.lastName}
              </td>{' '}
              <td>{d?.saleChannel}</td>
              <td>{d?.fleetName}</td>
              <td>
                {capitalize(d?.customer?.firstName)} {'  '}
                {capitalize(d?.customer?.lastName)}
              </td>
              <td>
                {d?.customer?.identificationType}-{d?.customer?.identification}
              </td>
              <td>{d?.customer?.gender}</td>
              <td>{d?.vehicle?.plate}</td>
              <td>
                {d?.customer?.phonePrefix}-{d?.customer?.phoneNumber}
              </td>
              <td>{d?.customer?.email?.toLowerCase()}</td>
              <td>{capitalize(d?.membershipCode)}</td>
              <td>{d?.vehicle?.year}</td>
              <td>{capitalize(d?.accidentRate)}</td>
              <td>{d?.finalPrice}</td>
              <td>
                {d?.endDate ? moment.utc(d?.endDate).format('DD/MM/YYYY') : ''}
              </td>
              <td>
                {d?.endRcv ? moment.utc(d?.endRcv).format('DD/MM/YYYY') : ''}
              </td>
              <td>{d?.contactInCampaign ? 'Contactar' : 'No Contactar'}</td>
            </tr>
          );
        })}
    </>
  );
};

export const LostTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    redirectToOrderDetail,
    tableRef,
    dateFrom,
    dateTo,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          <LostContentTable
            data={data}
            redirectToOrderDetail={redirectToOrderDetail}
          />
        </table>
        {/* Table for prindint */}
        <table
          style={{ display: 'none' }}
          ref={tableRef}
          className="sales-afiliation-table table"
        >
          <tr>
            {header?.map(h => {
              if (h?.hidePrint) {
                return null;
              } else {
                return <th key={h?.name}>{h?.name}</th>;
              }
            })}
          </tr>
          <LostContentTable
            data={data}
            print={true}
            redirectToOrderDetail={redirectToOrderDetail}
          />
        </table>

        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        <NoAvailableResults data={data} dateFrom={dateFrom} dateTo={dateTo} />
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetLoadDetailTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    redirectToEditOrder,
    tableRef,
    selectAllGiftcards,
    selectGiftcard,
  } = props;

  const SelectAllCheckbox = props => {
    const { action, data } = props;

    let selectedCounter = 0;
    let src = '';

    for (let i = 0; i < data.length; i++) {
      if (data[i]?.selected) {
        selectedCounter = selectedCounter + 1;
      }
    }

    selectedCounter === data.length
      ? (src = grayCheckboxSelected)
      : (src = grayCheckboxUnselected);

    return (
      <img
        style={{ cursor: 'pointer' }}
        src={src}
        alt="grayCheckboxSelected"
        onClick={() => action()}
      />
    );
  };

  const RenewCheckbox = props => {
    const { giftcard, select, index, selectedType } = props;

    if (
      giftcard?.fleetRenewed ||
      ![
        planStatus?.EXPIRED,
        planStatus?.TO_BE_EXPIRED,
        planStatus?.TO_BE_RENEW,
      ]?.includes(giftcard?.status)
    ) {
      return null;
    }

    if (giftcard?.status === 'Canjeada') {
      return null;
    }

    if (giftcard?.status !== selectedType && selectedType) {
      return null;
    }

    if (giftcard.selected) {
      return (
        <img
          style={{ cursor: 'pointer' }}
          src={grayCheckboxSelected}
          alt="grayCheckboxSelected"
          onClick={() => select(giftcard, index)}
        />
      );
    }
    if (!giftcard.selected) {
      return (
        <img
          style={{ cursor: 'pointer' }}
          src={grayCheckboxUnselected}
          alt="grayCheckboxSelected"
          onClick={() => select(giftcard, index)}
        />
      );
    } else return null;
  };

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            <td>
              <SelectAllCheckbox
                data={data?.data}
                action={selectAllGiftcards}
              />
            </td>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => {
              return (
                <tr key={d?._id}>
                  <td>
                    <RenewCheckbox
                      giftcard={d}
                      select={selectGiftcard}
                      index={i}
                      selectedType={data?.selectedType}
                    />
                  </td>
                  <td>{d?.membershipId}</td>
                  <td>{capitalize(d?.status)}</td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{d?.firstName}</td>
                  <td>{d?.lastName}</td>
                  <td>
                    {d?.identificationType}-{d?.identification}
                  </td>
                  <td>
                    {d?.phonePrefix}-{d?.phoneNumber}
                  </td>
                  <td>{d?.email?.toLowerCase()}</td>
                  <td>{capitalize(d?.state)}</td>
                  <td>{capitalize(d?.plan)}</td>
                  <td>{capitalize(d?.vehicleBrand)}</td>
                  <td>{capitalize(d?.vehicleModel)}</td>
                  <td>{d?.vehicleYear}</td>
                  <td>{d?.vehicleColor}</td>
                  <td>{d?.vehiclePlate}</td>
                  <td>
                    {d?.beginDate
                      ? moment.utc(d?.beginDate).format('DD/MM/YYYY')
                      : ''}
                  </td>{' '}
                  <td>
                    {d?.endDate
                      ? moment.utc(d?.endDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{d?.affiliationType}</td>
                  <td>{d?.rcv}</td>
                  <td>
                    {d?.rcvBeginDate
                      ? moment.utc(d?.rcvBeginDate).format('DD/MM/YYYY')
                      : ''}
                  </td>{' '}
                  <td>
                    {d?.rcvEndDate
                      ? moment.utc(d?.rcvEndDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => redirectToEditOrder(d?._id)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const RenewalMembershipTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    actionOverMembership,
    tableRef,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="sales-afiliation-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr
                  key={d?._id}
                  style={{ backgroundColor: checkMissingVehicleFields(d) }}
                >
                  <td>
                    <div className="table-buttons-conatiner">
                      <a href={`${editURL}/${d?._id}`}>
                        <div className="table-action-button">
                          <img target="_blank" src={editLogo} alt="editLogo" />
                        </div>
                      </a>
                      <TableActionButton
                        src={renewLogo}
                        action={() => actionOverMembership(d)}
                      />
                    </div>
                  </td>
                  <td>{d?.membershipId}</td>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  {/* <td>{d?.assignedAdvisor?.name}</td> */}
                  <td>{capitalize(d?.membershipStatus)}</td>
                  <td>{capitalize(d?.membershipCode)}</td>
                  <td>{d?.vehicle?.year}</td>
                  <td>{d?.armored ? 'Si' : 'No'}</td>
                  <td>{capitalize(d?.accidentRate)}</td>
                  <td>{d?.towQuantity}</td>
                  <td>{d?.discountPercentage}</td>
                  <td>
                    {capitalize(
                      d?.customer?.firstName + ' ' + d?.customer?.lastName
                    )}
                  </td>
                  <td>{d?.vehicle?.plate}</td>
                  <td>
                    {d?.customer?.identificationType}-
                    {d?.customer?.identification}
                  </td>
                  <td>
                    {d?.customer?.phonePrefix}-{d?.customer?.phoneNumber}
                  </td>
                  <td>{d?.customer?.email?.toLowerCase()}</td>
                  <td>{d?.saleChannel}</td>
                  {/* <td>{d?.contractType}</td> */}
                  <td>{moment.utc(d?.endDate).format('DD/MM/YYYY')}</td>
                  <td>{capitalize(d?.endMonth)}</td>
                  <td>{d?.affiliationType}</td>
                  <td>
                    {d?.rcvEndDate
                      ? moment.utc(d?.rcvEndDate).format('DD/MM/YYYY')
                      : 'N/A'}
                  </td>
                  <td>{d?.contactInCampaign ? 'Contactar' : 'No Contactar'}</td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetsRegisterTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    openDetail,
    tableRef,
    handleEditFleetForm,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.fleetId}</td>
                  <td>{d?.name}</td>
                  <td>{d?.code}</td>
                  <td>
                    {d?.isActive ? (
                      <img style={{ height: '35px' }} src={checkStatus} />
                    ) : (
                      <img style={{ height: '40px' }} src={calendarGray} />
                    )}
                  </td>
                  <td>{d?.fleetLoads?.length}</td>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{moment.utc(d?.updatedAt).format('DD/MM/YYYY')}</td>

                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={searchLogo}
                        action={() => openDetail(d)}
                      />
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditFleetForm(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const DiscountCodesTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    tableRef,
    handleEditCodeForm,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{d?.code}</td>
                  <td>
                    {d?.discountNumber}{' '}
                    {d?.discountType === 'Porcentaje' ? '%' : '$'}
                  </td>
                  <td>
                    {d?.appliesToRenewal ? (
                      <img src={checkStatus} alt="checkStatus" />
                    ) : (
                      <img src={close} alt="close" />
                    )}
                  </td>
                  <td>
                    {d?.appliesToNew ? (
                      <img src={checkStatus} alt="checkStatus" />
                    ) : (
                      <img src={close} alt="close" />
                    )}
                  </td>
                  <td style={{ fontSize: '12px' }}>
                    {d?.appliesToMemberships?.map(
                      a => capitalize(a?.code) + ' '
                    )}
                  </td>
                  <td>
                    {d?.validFrom
                      ? moment.utc(d?.validFrom).format('DD/MM/YYYY h:mm a')
                      : ''}
                  </td>
                  <td>
                    {d?.validTo
                      ? moment.utc(d?.validTo).format('DD/MM/YYYY h:mm a')
                      : ''}
                  </td>
                  <td>{d?.channel?.shortName}</td>
                  <td>{d?.campaign?.name}</td>
                  <td>{d?.status}</td>
                  <td>{d?.quantity}</td>
                  <td>{d?.usedByMemberships?.length}</td>
                  <td>
                    <TableActionButton
                      src={editLogo}
                      action={() => handleEditCodeForm(d)}
                    />
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const GiftcardTables = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    tableRef,
    handleEditCodeForm,
    handleDeleteGiftcardModal,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {d?.saleDate
                      ? moment.utc(d?.saleDate).format('DD/MM/YYYY')
                      : d?.saleDate}
                  </td>
                  <td>{capitalize(d?.membership?.code)}</td>
                  <td>{d?.channel?.shortName}</td>
                  <td>{d?.planAmount}</td>
                  <td>{d?.saleQuantity}</td>
                  <td>{d?.saleQuantity * d?.planAmount}</td>
                  <td>
                    {d?.createdAt
                      ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {d?.updatedAt
                      ? moment.utc(d?.updatedAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditCodeForm(d)}
                      />{' '}
                      <TableActionButton
                        src={trash}
                        action={() => handleDeleteGiftcardModal(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const GiftcardParticularTables = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    tableRef,
    handleEditCodeForm,
    handleDeleteGiftcardModal,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {d?.saleDate
                      ? moment.utc(d?.saleDate).format('DD/MM/YYYY')
                      : d?.saleDate}
                  </td>
                  <td>{capitalize(d?.membership?.code)}</td>
                  <td>{d?.channel?.shortName}</td>
                  <td>{d?.planAmount}</td>
                  <td>{d?.publicCode}</td>
                  <td>
                    {d?.customer?.firstName} {d?.customer?.lastName}
                  </td>
                  <td>
                    {d?.customer?.phonePrefix}-{d?.customer?.phoneNumber}
                  </td>
                  <td>{d?.customer?.email}</td>
                  <td>
                    {d?.seller?.firstName} {d?.seller?.lastName}
                  </td>
                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditCodeForm(d)}
                      />
                      <TableActionButton
                        src={trash}
                        action={() => handleDeleteGiftcardModal(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetLoadPaymentStatus = props => {
  const { payment, handleReportPaymentPlanModal, index } = props;

  if (
    [fleetLoadPaymentStatus?.PENDING, fleetLoadPaymentStatus?.EXPIRED].includes(
      payment?.status
    )
  ) {
    return (
      <span
        onClick={() => handleReportPaymentPlanModal(payment, index)}
        className="link-look-alike"
        style={{ color: '#FF722C' }}
      >
        Registrar pago
      </span>
    );
  } else {
    return null;
  }
};

export const PaymentPlanTable = props => {
  const { header, data, handleReportPaymentPlanModal, paymentForm } = props;
  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component"></div>
      <div className="table-container">
        <table className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.length > 0 &&
            data?.map((d, index) => {
              return (
                <tr key={d?._id}>
                  <td>{d?.number}</td>
                  <td>
                    {d?.dueDate
                      ? moment.utc(d?.dueDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{d?.amount}</td>
                  <td>{d?.status}</td>
                  <td>
                    <FleetLoadPaymentStatus
                      payment={d}
                      index={index}
                      handleReportPaymentPlanModal={
                        handleReportPaymentPlanModal
                      }
                    />
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </>
  );
};

export const GiftCardCheckbox = props => {
  const { giftcard, select, index, selectedType } = props;

  if (giftcard?.status === 'Canjeada') {
    return null;
  }

  if (giftcard?.status !== selectedType && selectedType) {
    return null;
  }

  if (giftcard.selected) {
    return (
      <img
        style={{ cursor: 'pointer' }}
        src={grayCheckboxSelected}
        alt="grayCheckboxSelected"
        onClick={() => select(giftcard, index)}
      />
    );
  }
  if (!giftcard.selected) {
    return (
      <img
        style={{ cursor: 'pointer' }}
        src={grayCheckboxUnselected}
        alt="grayCheckboxSelected"
        onClick={() => select(giftcard, index)}
      />
    );
  } else return null;
};

export const SelectAllCheckbox = props => {
  const { action, data } = props;

  let selectedCounter = 0;
  let src = '';

  for (let i = 0; i < data.length; i++) {
    if (data[i]?.selected) {
      selectedCounter = selectedCounter + 1;
    }
  }

  selectedCounter === data.length
    ? (src = grayCheckboxSelected)
    : (src = grayCheckboxUnselected);

  return (
    <img
      style={{ cursor: 'pointer' }}
      src={src}
      alt="grayCheckboxSelected"
      onClick={() => action()}
    />
  );
};

export const GiftcardCodesTables = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    tableRef,
    selectGiftcard,
    handleActivateGiftcardForm,
    handleBlockGiftcardForm,
    selectAllGiftcards,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            <th>
              <SelectAllCheckbox
                data={data?.data}
                action={selectAllGiftcards}
              />
            </th>

            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => {
              return (
                <tr key={d?._id}>
                  <td>
                    <GiftCardCheckbox
                      giftcard={d}
                      select={selectGiftcard}
                      index={i}
                      selectedType={data?.selectedType}
                    />
                  </td>
                  <td>{d?.publicCode}</td>
                  <td>{d?.privateCode}</td>
                  <td>{capitalize(d?.appliesToMembership?.code)}</td>
                  <td>
                    {d?.status === 'Inactiva' ? '' : d?.channel?.shortName}
                  </td>
                  <td>
                    {d?.status === 'Activa' && (
                      <img src={checkStatus} alt="checkStatus" />
                    )}
                    {d?.status === 'Inactiva' && (
                      <img src={close} alt="close" />
                    )}
                    {!['Activa', 'Inactiva'].includes(d?.status) && d?.status}
                  </td>
                  <td>
                    {d?.status === 'Activa' && (
                      <AltButton
                        label={'Bloquear'}
                        action={() => handleBlockGiftcardForm(d)}
                      />
                    )}
                    {d?.status === 'Inactiva' && (
                      <ActionButton
                        label={'Activar'}
                        action={() => handleActivateGiftcardForm(d)}
                      />
                    )}{' '}
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const SalesUserTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    openDetail,
    tableRef,
    handleEditFleetForm,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {d?.firstName} {d?.lastName}
                  </td>
                  <td>{d?.email}</td>
                  <td>{transcriptRoles(d?.role)}</td>
                  <td>{exists(d?.saleChannel?.channel)}</td>
                  <td>{d?.status}</td>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{moment.utc(d?.updatedAt).format('DD/MM/YYYY')}</td>

                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditFleetForm(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const SalesChannelsTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    tableRef,
  } = props;
  console.log('data:', data)




  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.name}</td>
                  <td>
                    <CopyToClipboard
                      text={d?.f4Link}
                    >
                      <div className="col-5 copy-payment-data-image-button-container">
                        <h6>Copiar</h6>
                      </div>
                    </CopyToClipboard>

                  </td>
                  <td><CopyToClipboard
                    text={d?.retailLink}
                  >
                    <div className="col-5 copy-payment-data-image-button-container">
                      <h6>Copiar</h6>
                    </div>
                  </CopyToClipboard></td>
                  <td><a target="_blank"
                    href={d?.qrCode}>Código</a></td>
                  <td><a target="_blank"
                    href={d?.qrCodePlusF4}>Código</a></td>
                  <td>{d?.isActive ? "Activo" : "Inactivo"}</td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};


export const FleetsDetailTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    handleCancelFleetLoadForm,
    handleDeleteFleetLoadForm,
    handleActivateFleetLoadForm,
    handleStatusModal,
    handlePaymentPlanModal,
    redirectToAffiliationLink,
    redirectToLoadDetail,
    tableRef,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.fleetLoadId}</td>
                  <td>{d?.type}</td>
                  <td>{d?.contractPrice}</td>
                  <td>
                    <span
                      onClick={() => handlePaymentPlanModal(d)}
                      className="link-look-alike"
                    >
                      {capitalize(d?.paymentMethod) + ' '}
                      {capitalize(d?.financingTerm)}
                    </span>
                  </td>
                  <td>
                    {d?.planStartDate
                      ? moment.utc(d?.planStartDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {d?.planEndDate
                      ? moment.utc(d?.planEndDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{d?.memberships?.length}</td>
                  <td>
                    {d?.loadStrategy === 'Link' && (
                      <span
                        onClick={() => redirectToAffiliationLink(d?.linkToken)}
                        className="link-look-alike"
                      >
                        Link
                      </span>
                    )}
                  </td>
                  <td>
                    <span
                      onClick={() => handleStatusModal(d)}
                      className="link-look-alike"
                    >
                      {d?.status}
                    </span>
                  </td>
                  <td>
                    {d?._id && (
                      <span
                        onClick={() => redirectToLoadDetail(d?._id)}
                        className="link-look-alike"
                      >
                        Detalles
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="table-buttons-conatiner">
                      {!['Cancelada', 'Anulada', 'Eliminada'].includes(
                        d?.status
                      ) && (
                          <TableActionButton
                            src={offLogo}
                            action={() => handleCancelFleetLoadForm(d)}
                          />
                        )}
                      {['Cancelada', 'Anulada'].includes(d?.status) && (
                        <TableActionButton
                          src={onLogo}
                          action={() => handleActivateFleetLoadForm(d)}
                        />
                      )}
                      {!['Eliminada'].includes(d?.status) && (
                        <TableActionButton
                          src={trash}
                          action={() => handleDeleteFleetLoadForm(d)}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const PricesTable = props => {
  const { header, data, title, tableRef } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="prices-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.map((d, i) => {
            return (
              <>
                <tr
                  className={`fila-` + (i % 2 == 0 ? 'par' : 'impar')}
                  key={d?._id}
                >
                  <td className="precentage precentage-up">
                    <b>
                      {d?.percentage === 0
                        ? 'Precio Full'
                        : d?.percentage + '% OFF'}{' '}
                    </b>
                  </td>
                  <td>{d?.plans?.classic?.usd} $</td>
                  <td>{d?.plans?.plus?.usd} $</td>
                  <td>{d?.plans?.gold?.usd} $</td>
                  <td>{d?.plans?.motorider?.usd} $</td>
                </tr>
                <tr className={`fila-` + (i % 2 == 0 ? 'par' : 'impar')}>
                  <td className="precentage precentage-down"></td>
                  <td>{d?.plans?.classic?.bss} BsS</td>
                  <td>{d?.plans?.plus?.bss} BsS</td>
                  <td>{d?.plans?.gold?.bss} BsS</td>{' '}
                  <td>{d?.plans?.motorider?.bss} BsS</td>{' '}
                </tr>
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export const VehiclesMembershipTable = props => {
  const { header, data, title, handleHistoryModal, redirectToEdit } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="vehicles-table table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.length > 0 &&
            data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    <TableActionButton
                      src={editLogo}
                      action={() => redirectToEdit(d?._id)}
                    />
                  </td>
                  <td>{d?.saleChannel}</td>
                  <td>{d?.membershipId}</td>
                  <td>{capitalize(d?.code)}</td>
                  <td>{capitalize(d?.status)}</td>
                  {/* <td>{d?.avQuantity ? d?.avQuantity : 'N/A'}</td> */}
                  <td>{d?.towServices?.length}</td>
                  <td>{d?.accidentRate}</td>
                  <td>{d?.affiliationType}</td>
                  <td>{capitalize(d?.seller)}</td>
                  <td>
                    {d?.beginDate
                      ? moment.utc(d?.beginDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {d?.endDate
                      ? moment.utc(d?.endDate).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{capitalize(d?.rcv)}</td>
                  <td>
                    <img
                      style={{ cursor: 'pointer' }}
                      src={eye}
                      onClick={() => handleHistoryModal(d)}
                    />
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </>
  );
};

const Service = props => {
  const { service } = props;

  return <span>{capitalize(service?.product)}</span>;
};

export const MembershipHistoryTable = props => {
  const { header, data } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data?.services?.length > 0 &&
            data?.services?.map(d => (
              <tr key={d?._id}>
                <td>
                  {d?.createdAt
                    ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td>{capitalize(d?.fault)}</td>
                <td>{capitalize(d?.product)}</td>
                <td>{d?.km ? d?.km + ' Km' : 'N/A'}</td>
              </tr>
            ))}
        </table>
        {data.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </>
  );
};
