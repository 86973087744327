import { ActionButton, AltButton } from '../../Buttons/Buttons';
import './NewPaymentForm.scss';

export const IGTFMessage = (props) => {
  const { method, amount, acceptIGTFMethod, denyIGTFMethod } = props;

  if (amount && method && method?.currency !== 'BsS') {
    return (
      <div
        style={{ marginTop: '56px', marginBottom: '56px' }}
        className="monto-con-igtf col-12"
      >
        <h6>
          ¿Desea cancelar la totalidad del monto y el recargo por IGTF en{' '}
          {method?.name}?
        </h6>
        <h5>
          ${parseFloat(amount)} + ${(parseFloat(amount) * 0.03)?.toFixed(2)} =
          ${(parseFloat(amount) + parseFloat(amount) * 0.03)?.toFixed(2)}
        </h5>
        <p style={{ textAlign: 'center' }}>
          * En caso de seleccionar "No", el recargo de IGTF se cobrara en un
          segundo metodo de pago
        </p>
        <div className="buttons-center-container">
          <AltButton label={'No'} action={() => denyIGTFMethod()} />
          <ActionButton label={'Si'} action={() => acceptIGTFMethod()} />
        </div>
      </div>
    );
  } else return null;
};
